import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import reactIcon from '../assets/icon/stack/react.svg';
import vueIcon from '../assets/icon/stack/vue.svg';
import webpackIcon from '../assets/icon/stack/webpack.svg';
import gitIcon from '../assets/icon/stack/git.png';
import sassIcon from '../assets/icon/stack/sass.svg';
import nodeIcon from '../assets/icon/stack/node.png';
import WPIcon from '../assets/icon/stack/wordpress.svg';
import strapiIcon from '../assets/icon/stack/strapi.jpg';
import JSIcon from '../assets/icon/stack/js.png';
import reduxIcon from '../assets/icon/stack/redux.svg';
import htmlIcon from '../assets/icon/stack/HTML5_Logo.svg';
import cssIcon from '../assets/icon/stack/CSS3_logo_and_wordmark.svg';
import passportIcon from '../assets/icon/stack/passport.svg';
import mongodbIcon from '../assets/icon/stack/mongodb.svg';
import typescriptIcon from '../assets/icon/stack/ts.png';

class Competences extends Component {
  render() {
    const { section } = this.props;
    return (
      <section id="competences" ref={section}>
        <Container>
          <Row className="main__row__responsive">
            <Col xs={4} as="h1" data-aos-duration="900" data-aos="fade-up">
              <mark>COMPÉTENCES</mark>
            </Col>
            <Col xs={0} className="d-none d-lg-flex  flex-column">
              <div
                className="line"
                data-aos-duration="1000"
                data-aos-delay="500"
                data-aos="deploy-line"
              />
              <div className="round" />
            </Col>
            <Col>
              <div className="main__content">
                <Container>
                  <Row className="mb-5" data-aos-duration="900" data-aos="fade-up">
                    <div className="row__content">
                      <h3 className="skill__title">FRONTEND</h3>
                      <div className="skill__image">
                        <div className="icon__container">
                          <img src={reactIcon} alt="reactjs icon" />
                          <div className="img__caption">React</div>
                        </div>
                        <div className="icon__container">
                          <img src={reduxIcon} alt="redux icon" />
                          <div className="img__caption">Redux</div>
                        </div>
                        <div className="icon__container">
                          <img src={vueIcon} alt="vuejs icon" />
                          <div className="img__caption">Vue</div>
                        </div>
                        <div className="icon__container">
                          <img src={JSIcon} alt="javascript icon" />
                          <div className="img__caption">JavaScript</div>
                        </div>
                        <div className="icon__container">
                          <img src={htmlIcon} alt="html icon" className="html" />
                          <div className="img__caption">HTML5</div>
                        </div>
                        <div className="icon__container">
                          <img src={sassIcon} alt="sass icon" />
                          <div className="img__caption">SASS</div>
                        </div>
                        <div className="icon__container">
                          <img src={cssIcon} alt="css icon" className="css" />
                          <div className="img__caption">CSS3</div>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <Row className="my-5" data-aos-duration="900" data-aos="fade-up">
                    <div className="row__content">
                      <h3 className="skill__title">BACKEND</h3>
                      <div className="skill__image">
                        <div className="icon__container">
                          <img src={nodeIcon} alt="nodejs icon" className="node" />
                          <div className="img__caption">Node</div>
                        </div>
                        <div className="icon__container">
                          <img src={passportIcon} alt="passportjs icon" />
                          <div className="img__caption">Passport</div>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <Row className="my-5" data-aos-duration="900" data-aos="fade-up">
                    <div className="row__content">
                      <h3 className="skill__title">DATABASE</h3>
                      <div className="skill__image">

                        <div className="icon__container">
                          <img src={mongodbIcon} alt="mongodb icon" className="mongodb" />
                          <div className="img__caption">mongoDB</div>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <Row className="my-5" data-aos-duration="900" data-aos="fade-up">
                    <div className="row__content">
                      <h3 className="skill__title">WORKFLOW</h3>
                      <div className="skill__image">
                        <div className="icon__container">
                          <img src={webpackIcon} alt="webpack icon" />
                          <div className="img__caption">Webpack</div>
                        </div>
                        <div className="icon__container">
                          <img src={gitIcon} alt="git icon" />
                          <div className="img__caption">Git</div>
                        </div>
                        <div className="icon__container">
                          <img src={typescriptIcon} alt="typescript icon" />
                          <div className="img__caption">TypeScript</div>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <Row className="mt-5" data-aos-duration="900" data-aos="fade-up">
                    <div className="row__content">
                      <h3 className="skill__title">CMS</h3>
                      <div className="skill__image">
                        <div className="icon__container">
                          <img src={WPIcon} alt="wordpress icon" />
                          <div className="img__caption">WordPress</div>
                        </div>
                        <div className="icon__container">
                          <img src={strapiIcon} alt="strapi icon" />
                          <div className="img__caption">Strapi</div>
                        </div>
                      </div>
                    </div>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default Competences;
