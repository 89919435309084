import React, { Component } from 'react';
import Parallax from 'react-rellax';
import backgroundImage from '../assets/cubic-background.jpg';

class Banner extends Component {
  render() {
    return (
      <section id="banner">
        <div className="img__container">
          <img src={backgroundImage} alt="cubic background" />
        </div>
        <Parallax
          as="div"
          speed={2}
          className="d-flex align-items-center justify-content-center vh-100"
        >
          <div className="d-flex align-items-center justify-content-center flex-column">
            <div className="mb-4 text-center" data-aos-duration="1000" data-aos="custom-fade-up">
              <h1>NICOLAS TARABORRELLI</h1>
              <h1 className="subtitle">
                <strong>DEVELOPPEUR </strong>
                WEB
                <strong> FREELANCE</strong>
              </h1>
            </div>
            <div
              className="content"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="1000"
            >
              <p className="text-center">
                À votre écoute pour réaliser vos
                {' '}
                <strong>sites</strong>
                {' '}
et
                {' '}
                <strong>applications</strong>
              </p>
            </div>
          </div>
        </Parallax>
      </section>
    );
  }
}

export default Banner;
