import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
import Parallax from 'react-rellax';
import Article from './Article';

import measyScreenshot from '../assets/screenshot/measy.png';
import measyMobileScreenshot from '../assets/screenshot/measy-mobile.png';
import lePotagerRegalScreenshot from '../assets/screenshot/le-potager-regal.jpg';
import lePotagerRegalMobileScreenshot from '../assets/screenshot/le-potager-regal-mobile.png';

class Realisations extends Component {
  render() {
    const { section } = this.props;
    const articleData = {
      measy: {
        name: 'MEASY',
        src: {
          desktop: measyScreenshot,
          mobile: measyMobileScreenshot,
        },
        altImage: 'screenshot measy',
        url: 'https://measy.nicolastaraborrelli.fr',
        smallUrl: 'measy.fr',
        altUrl: 'url lien link measy measy.fr',
        missions: [
          'Lancement de produit en lean startup',
          'Intégration front',
          'Co-réalisation graphique',
          'Backend (gestion du contenu backoffice, stockage des inscriptions)',
        ],
        stacks: [
          'ReactJS',
          'Redux',
          'NodeJS',
          'Ubuntu',
          'MongoDB',
          'AWS (EC2)',
          'SASS',
          'Bootstrap',
        ],
      },
      lePotagerRegal: {
        name: 'LE POTAGER RÉGAL',
        src: {
          desktop: lePotagerRegalScreenshot,
          mobile: lePotagerRegalMobileScreenshot,
        },
        altImage: 'screenshot le potager régal',
        url: 'https://lepotagerregal.fr',
        smallUrl: 'lepotagerregal.fr',
        altUrl: 'url lien link lepotagerregal le potager régal lepotagerregal.fr',
        missions: [
          'Réalisation graphique',
          'Intégration front',
          'Mise en place d\'un back office WordPress',
          'Référencement naturel (SEO)',
          'Formulaire de contact',
        ],
        stacks: ['ReactJS', 'PHP', 'WordPress', 'ACF', 'SASS', 'Bootstrap'],
      },
    };

    return (
      <section id="realisations" ref={section}>
        <Container style={{ position: 'relative' }}>
          <Row
            as="h1"
            className="justify-content-center"
            data-aos-duration="1000"
            data-aos="custom-fade-down"
          >
            <Parallax as="div" speed={-0.5}>
              <mark>RÉALISATIONS</mark>
            </Parallax>
          </Row>
          <Article data={articleData.measy} />
        </Container>
      </section>
    );
  }
}

export default Realisations;
