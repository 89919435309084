import React, { Component } from 'react';
import Scrollspy from 'react-scrollspy';
import AOS from 'aos';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false,
      toggled: false,
    };
  }

  burgerHandler() {
    const { toggled } = this.state;
    let className = 'burger__icon';
    className += toggled ? ' js-toggle--active' : ' js-toggle--inactive';
    return className;
  }

  navbarEvent(event) {
    if (typeof event !== 'undefined') {
      switch (event.id) {
        case 'banner':
          this.setState({ scrolled: false });
          break;
        case 'realisations':
          AOS.refresh();
          this.setState({ scrolled: true });
          break;
        default:
          break;
      }
    }
  }

  navbarHandler() {
    const { scrolled, toggled } = this.state;
    let className = 'navbar toggle';
    className += scrolled ? ' js-scrolled--active' : ' js-scrolled--inactive';
    className += toggled ? ' js-toggle--active' : ' js-toggle--inactive';
    return className;
  }

  scrollTo(e, id) {
    const { section } = this.props;
    e.preventDefault();
    window.scrollTo({ behavior: 'smooth', top: section[id].current.offsetTop });
    this.setState({ toggled: false });
  }

  toggleHandler() {
    this.setState((prevState) => {
      return { toggled: !prevState.toggled };
    });
  }

  render() {
    return (
      <Scrollspy
        componentTag="header"
        items={['banner', 'realisations', 'services', 'competences', 'contact']}
        currentClassName="js-current"
        className={this.navbarHandler()}
        onUpdate={(event) => {
          return this.navbarEvent(event);
        }}
        offset={-100}
      >
        {/* eslint-disable-next-line */}
        <a href="#banner" className="navbar__item" />
        <a href="#realisations" className="navbar__item">
          <span
            role="menuitem"
            tabIndex="0"
            onClick={(e) => {
              return this.scrollTo(e, 'realisations');
            }}
            onKeyDown={(e) => {
              return this.scrollTo(e, 'realisations');
            }}
          >
            Réalisations
          </span>
        </a>
        <a href="#services" className="navbar__item">
          <span
            role="menuitem"
            tabIndex="0"
            onClick={(e) => {
              return this.scrollTo(e, 'services');
            }}
            onKeyDown={(e) => {
              return this.scrollTo(e, 'services');
            }}
          >
            Services
          </span>
        </a>
        <a href="#competences" className="navbar__item">
          <span
            role="menuitem"
            tabIndex="0"
            onClick={(e) => {
              return this.scrollTo(e, 'competences');
            }}
            onKeyDown={(e) => {
              return this.scrollTo(e, 'competences');
            }}
          >
            Compétences
          </span>
        </a>
        <a href="#contact" className="navbar__item">
          <span
            role="menuitem"
            tabIndex="0"
            onClick={(e) => {
              return this.scrollTo(e, 'contact');
            }}
            onKeyDown={(e) => {
              return this.scrollTo(e, 'contact');
            }}
          >
            Contact
          </span>
        </a>
        <div
          role="menuitem"
          tabIndex="0"
          className={this.burgerHandler()}
          onClick={() => {
            return this.toggleHandler();
          }}
          onKeyDown={() => {
            return this.toggleHandler();
          }}
        >
          <input type="checkbox" />
          <span />
          <span />
          <span />
        </div>
      </Scrollspy>
    );
  }
}

export default Header;
