import React, { Component } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './styles/app.scss';

import Header from './Components/Header';
import Banner from './Components/Banner';
import Realisations from './Components/Realisations';
import Services from './Components/Services';
import Competences from './Components/Competences';
import Contact from './Components/Contact';
import Footer from './Components/Footer';

class App extends Component {
  constructor(props) {
    super(props);
    this.section = {
      realisations: React.createRef(),
      services: React.createRef(),
      competences: React.createRef(),
      contact: React.createRef(),
    };
  }

  componentDidMount() {
    AOS.init({ once: true });
  }

  render() {
    return (
      <div className="App">
        <Header section={this.section} />
        <Banner />
        {/*<Realisations section={this.section.realisations} /> */}
        <Services section={this.section.services} />
        <Competences section={this.section.competences} />
        <Contact section={this.section.contact} />
        <Footer />
      </div>
    );
  }
}

export default App;
