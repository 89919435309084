import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import stackIcon from '../assets/icon/social/stack.svg';
import linkedinIcon from '../assets/icon/social/linkedin.svg';
import githubIcon from '../assets/icon/social/github.svg';
import maltIcon from '../assets/icon/social/malt.svg';

class Footer extends Component {
  render() {
    return (
      <footer id="footer">
        <Container>
          <Row>
            <Col sm={6} md={7} lg={9}>
              <div className="footer__title">© 2019 | Nicolas TARABORRELLI </div>
            </Col>
            <Col>
              <Row className="justify-content-center">
                <div className="icon__container">
                  <a
                    href="https://www.linkedin.com/in/ntaraborrelli"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img src={linkedinIcon} alt="linkedin icon" />
                  </a>
                </div>
                <div className="icon__container">
                  <a href="https://github.com/Borrelli" rel="noopener noreferrer" target="_blank">
                    <img src={githubIcon} alt="github icon" />
                  </a>
                </div>
                <div className="icon__container">
                  <a href="https://www.malt.fr/profile/nicolastaraborrelli" rel="noopener noreferrer" target="_blank">
                    <img src={maltIcon} alt="malt icon" />
                  </a>
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default Footer;
