import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import backIcon from '../assets/icon/others/back.svg';

class Article extends Component {
  constructor(props) {
    super(props);
    this.state = {
      captionDeployed: false,
    };
  }

  captionHandler() {
    const { captionDeployed } = this.state;
    let className = 'article__container d-flex align-items-center';
    className += captionDeployed ? ' article--deployed' : ' article--reduced';
    return className;
  }

  swipArticle(id) {
    switch (id) {
      case 'home':
        this.setState({ captionDeployed: true });
        break;
      case 'details':
        this.setState({ captionDeployed: false });
        break;
      default:
        break;
    }
  }

  render() {
    const { data } = this.props;

    const listMission = data.missions.map((mission) => {
      return <li key={mission.toString()}>{mission}</li>;
    });

    const listStack = data.stacks.map((stack) => {
      return <li key={stack.toString()}>{stack}</li>;
    });

    return (
      <Row as="article" className="main__row__responsive py-5">
        {/* Desktop */}
        <Col className="d-none d-md-block article--desktop">
          <Container>
            <Row as="h2">{data.name}</Row>
            <Row className={this.captionHandler()}>
              <div
                role="button"
                tabIndex="0"
                className="img__container"
                onClick={() => {
                  this.swipArticle('home');
                }}
                onKeyDown={() => {
                  this.swipArticle('home');
                }}
              >
                <img src={data.src.desktop} alt={data.altImage} />
              </div>
              <div className="caption">
                <div className="caption__button__container">
                  <div
                    className="caption__button__content"
                    role="button"
                    tabIndex="0"
                    onClick={() => {
                      this.swipArticle('home');
                    }}
                    onKeyDown={() => {
                      this.swipArticle('home');
                    }}
                  >
                    <p>Détails</p>
                  </div>
                  <div className="article__description">
                    <Container className="mt-3">
                      <Row>
                        <Col>
                          <Container className="mx-5">
                            <h3>
                              <mark>MISSIONS RÉALISÉES</mark>
                            </h3>
                            <ul>{listMission}</ul>
                          </Container>
                        </Col>
                        <Col>
                          <Container className="mx-5">
                            <h3>
                              <mark>STACK UTILISÉS</mark>
                            </h3>
                            <ul>{listStack}</ul>
                          </Container>
                        </Col>
                      </Row>
                      <Row className="mt-5 article__footer">
                        <Col className="d-flex justify-content-center align-items-center">
                          <h3 className="visit-url">
                            <a href={data.url} rel="noopener noreferrer" target="_blank">
                              Site :
                              {' '}
                              {data.smallUrl}
                            </a>
                          </h3>
                        </Col>
                        <Col className="d-flex justify-content-center align-items-center">
                          <div
                            role="button"
                            tabIndex="0"
                            className="icon__container"
                            onClick={() => {
                              this.swipArticle('details');
                            }}
                            onKeyDown={() => {
                              this.swipArticle('details');
                            }}
                          >
                            <img src={backIcon} alt="back icon" />
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </Col>
        {/* Mobile */}
        <Col className="d-md-none article--mobile">
          <Container>
            <Row as="h2">{data.name}</Row>
            <Row className={this.captionHandler()}>
              <a href={data.url} rel="noopener noreferrer" target="_blank">
                <img src={data.src.mobile} alt={data.altImage} />
              </a>
              <div className="article__description">
                <Container className="mt-3">
                  <Row>
                    <Container className="mx-2">
                      <h3>
                        <mark>MISSIONS RÉALISÉES</mark>
                      </h3>
                      <ul>{listMission}</ul>
                    </Container>
                  </Row>
                  <Row>
                    <Container className="mx-2">
                      <h3>
                        <mark>STACK UTILISÉES</mark>
                      </h3>
                      <ul>{listStack}</ul>
                    </Container>
                  </Row>
                </Container>
              </div>
            </Row>
          </Container>
        </Col>
      </Row>
    );
  }
}

export default Article;
