import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import integrationIcon from '../assets/icon/others/computer.svg';
import responsiveIcon from '../assets/icon/others/mobile.svg';
import UXIcon from '../assets/icon/others/develop.svg';

class Services extends Component {
  render() {
    const { section } = this.props;
    return (
      <section id="services" ref={section}>
        <Container>
          <Row className="main__row__responsive">
            <Col xs={4} as="h1" data-aos-duration="900" data-aos="fade-up">
              <mark>SERVICES</mark>
            </Col>
            <Col xs={0} className="d-none d-lg-flex flex-column">
              <div
                className="line"
                data-aos-duration="1000"
                data-aos-delay="500"
                data-aos="deploy-line"
              />
              <div className="round" />
            </Col>
            <Col>
              <div className="main__content">
                <Container>
                  <Row className="mb-5" data-aos-duration="900" data-aos="fade-up">
                    <Col xs={3}>
                      <div className="icon__container">
                        <img src={integrationIcon} alt="computer icon" />
                      </div>
                    </Col>

                    <Col>
                      <Row as="h3">CRÉATION DE SITE WEB</Row>
                      <Row>
                        <p>
                          Je réalise votre
                          <strong> site web </strong>
                          et votre
                          <strong> application backoffice.</strong>
                          <br />
                          Vous souhaitez
                          <strong> créer un site </strong>
                          à votre image ? Mettre en avant vos produits et services ? Gérer vos
                          commandes ?
                          <br />
                          Posez-moi vos questions via le formulaire de contact et nous discuterons
                          de votre projet.
                        </p>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="my-5" data-aos-duration="900" data-aos="fade-up">
                    <Col xs={3}>
                      <div className="icon__container">
                        <img src={responsiveIcon} alt="mobile icon" />
                      </div>
                    </Col>
                    <Col>
                      <Row as="h3">RESPONSIVE DESIGN</Row>
                      <Row>
                        <p>
                          Votre projet est
                          <strong> compatible sur tous les supports.</strong>
                          <br />
                          Votre site
                          <strong> s’adapte automatiquement en fonction de l'équipement </strong>
                          de l’utilisateur de façon à ce qu'il soit consultable partout !
                        </p>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mt-5" data-aos-duration="900" data-aos="fade-up">
                    <Col xs={3}>
                      <div className="icon__container">
                        <img src={UXIcon} alt="UX icon" />
                      </div>
                    </Col>
                    <Col>
                      <Row as="h3">EXPERIENCE UTILISATEUR</Row>
                      <Row>
                        <p>
                          Une approche
                          <strong> centrée sur l’utilisateur.</strong>
                          <br />
                          J’offre à vos visiteurs une
                          <strong> expérience de navigation simple et intuitive </strong>
à travers
                          une interface claire et intelligente.
                        </p>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default Services;
